import React, { useState, useEffect } from "react";
import "./styles.css";
import WheelComponent from "react-wheel-of-prizes";
import "react-wheel-of-prizes/dist/index.css";

// Données des utilisateurs (à remplacer par les données réelles du fichier users.json)
const usersData = {
  "users": [
    {"username": "user1", "password": "pass1"},
    {"username": "user2", "password": "pass2"},
    {"username": "user2", "password": "pass2"},
    {"username": "user2", "password": "pass2"},
    {"username": "user2", "password": "pass2"}
    // Ajoutez d'autres utilisateurs ici si nécessaire
  ]
};

const App = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false); // Ajouté pour suivre si l'utilisateur a déjà joué
  const [results, setResults] = useState([]);

  // Configurations pour la roue de la fortune
  const segments = [
    "better luck next time",
    "won 70",
    "won 10",
    "better luck next time",
    "won 2",
    "won uber pass",
    "better luck next time",
    "won a voucher"
  ];
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000"
  ];

  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser) {
      const foundUser = usersData.users.find(user => user.username === loggedInUser);
      if (foundUser) {
        setIsLoggedIn(true);
        setUsername(loggedInUser);
        const playedStatus = localStorage.getItem("hasPlayed_" + loggedInUser);
        setHasPlayed(playedStatus === "true"); // Vérifier si l'utilisateur a déjà joué
        const savedResults = localStorage.getItem("wheelResults_" + loggedInUser);
        if (savedResults) {
          setResults(JSON.parse(savedResults));
        }
      }
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const foundUser = usersData.users.find(user => user.username === username && user.password === password);
    if (foundUser) {
      setIsLoggedIn(true);
      localStorage.setItem("loggedInUser", username);
    } else {
      alert("Invalid username or password");
    }
  };

  const onFinished = (winner) => {
    if (!hasPlayed) {
      setResults([...results, winner]);
      localStorage.setItem("wheelResults_" + username, JSON.stringify([...results, winner]));
      setHasPlayed(true);
      localStorage.setItem("hasPlayed_" + username, "true"); // Marquer l'utilisateur comme ayant joué
      if (winner !== "replay") {
        setIsLoggedIn(false);
        localStorage.removeItem("loggedInUser");
      }
    } else {
      alert("You have already played.");
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-form">
        <form onSubmit={handleLogin}>
          <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <button type="submit">Login</button>
        </form>
      </div>
    );
  }

  return (
    <div className="wheel-box">
      <WheelComponent
        segments={segments}
        segColors={segColors}
        winningSegment="won 60" // Ajustez cette valeur selon votre logique
        onFinished={(winner) => onFinished(winner)}
        primaryColor="black"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={false}
        size={290}
        upDuration={100}
        downDuration={1000}
        fontFamily="Arial"
      />
      <div>
        {results.map((result, index) => (
          <p key={index}>Result {index + 1}: {result}</p>
        ))}
      </div>
    </div>
  );
};

export default App;
